import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "fw-bolder" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_7 = { class: "card-body p-9" }
const _hoisted_8 = { class: "mb-10" }
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = { class: "d-flex flex-wrap py-5" }
const _hoisted_11 = { class: "flex-equal me-5" }
const _hoisted_12 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_13 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_14 = { class: "text-gray-800 min-w-200px" }
const _hoisted_15 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_16 = { class: "text-gray-800 min-w-200px" }
const _hoisted_17 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_18 = { class: "text-gray-800 min-w-200px" }
const _hoisted_19 = { class: "text-gray-400" }
const _hoisted_20 = { class: "text-gray-800" }
const _hoisted_21 = { class: "text-gray-400" }
const _hoisted_22 = { class: "text-gray-800" }
const _hoisted_23 = { class: "text-gray-400" }
const _hoisted_24 = { class: "text-gray-800" }
const _hoisted_25 = { class: "text-gray-400" }
const _hoisted_26 = { class: "text-gray-800" }
const _hoisted_27 = { class: "text-gray-400" }
const _hoisted_28 = { class: "text-gray-800" }
const _hoisted_29 = { class: "text-gray-400" }
const _hoisted_30 = { class: "text-gray-800" }
const _hoisted_31 = { class: "flex-aqual" }
const _hoisted_32 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_33 = { class: "text-gray-800 min-w-200px" }
const _hoisted_34 = ["src"]
const _hoisted_35 = { class: "card-body pt-5 border-top" }
const _hoisted_36 = { class: "timeline-label" }
const _hoisted_37 = { class: "timeline-label fw-bolder text-gray-800 fs-7" }
const _hoisted_38 = { class: "timeline-badge" }
const _hoisted_39 = {
  key: 0,
  class: "fa fa-genderless text-success fs-1"
}
const _hoisted_40 = {
  key: 1,
  class: "fa fa-genderless text-warning fs-1"
}
const _hoisted_41 = {
  key: 2,
  class: "fa fa-genderless text-danger fs-1"
}
const _hoisted_42 = { class: "timeline-content" }
const _hoisted_43 = { class: "d-flex flex-column" }
const _hoisted_44 = { class: "fw-bolder text-gray-800 ps-3" }
const _hoisted_45 = {
  key: 0,
  class: "fw-bolder text-gray-600 ps-3"
}
const _hoisted_46 = {
  key: 0,
  class: "card card-toolbar w-50"
}
const _hoisted_47 = { class: "nav" }
const _hoisted_48 = { class: "nav-item" }
const _hoisted_49 = {
  class: "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1 active",
  "data-bs-toggle": "tab",
  href: "#attachment_tab_images"
}
const _hoisted_50 = { class: "nav-item" }
const _hoisted_51 = {
  class: "nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1",
  "data-bs-toggle": "tab",
  href: "#attachment_tab_customer_signature"
}
const _hoisted_52 = {
  key: 0,
  class: "card-body pt-5"
}
const _hoisted_53 = { class: "tab-content" }
const _hoisted_54 = {
  class: "tab-pane fade active show",
  id: "attachment_tab_images"
}
const _hoisted_55 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_56 = { class: "d-flex flex-row-fluid flex-wrap align-items-center" }
const _hoisted_57 = { class: "flex-grow-1 me-2" }
const _hoisted_58 = { class: "svg-icon-2 svg-icon-primary" }
const _hoisted_59 = {
  key: 1,
  class: "card-body pt-5"
}
const _hoisted_60 = { class: "tab-content" }
const _hoisted_61 = {
  class: "tab-pane fade",
  id: "attachment_tab_customer_signature"
}
const _hoisted_62 = { class: "symbol symbol-60px symbol-2by3 me-4" }
const _hoisted_63 = { class: "d-flex flex-row-fluid flex-wrap align-items-center" }
const _hoisted_64 = { class: "flex-grow-1 me-2" }
const _hoisted_65 = { class: "svg-icon-2 svg-icon-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.translate('packageDetails')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              class: "btn btn-light btn-active-light-primary me-2",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
            }, _toDisplayString(_ctx.translate('Back To List')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.translate('basicInfo')) + ":", 1),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("table", _hoisted_12, [
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.translate('areaName')) + ": ", 1),
                  _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.packages[0].areaName ? _ctx.packages[0].areaName : 'N/A'), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.translate('Governorate Name')) + ": ", 1),
                  _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.packages[0].governorateName
                        ? _ctx.packages[0].governorateName
                        : 'N/A'), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.translate('holderName')) + ": ", 1),
                  _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.packages[0].holderName ? _ctx.packages[0].holderName : 'N/A'), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.translate('licantEmail')) + ":", 1),
                  _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.packages[0].address ? _ctx.packages[0].address : 'N/A'), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.translate('docId')) + ":", 1),
                  _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.packages[0].docId ? _ctx.packages[0].docId : 'N/A'), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.translate('notes')) + ":", 1),
                  _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.packages[0].notes ? _ctx.packages[0].notes : 'N/A'), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.translate('phoneNumber')) + ":", 1),
                  _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.packages[0].recipientPhoneNumber
                        ? _ctx.packages[0].recipientPhoneNumber
                        : 'N/A'), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_27, _toDisplayString(_ctx.translate('secondPhoneNumber')) + ": ", 1),
                  _createElementVNode("td", _hoisted_28, _toDisplayString(_ctx.packages[0].secondRecipientPhoneNumber
                        ? _ctx.packages[0].secondRecipientPhoneNumber
                        : 'N/A'), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_29, _toDisplayString(_ctx.translate('Package ID')) + ":", 1),
                  _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.packages[0].trackNumber ? _ctx.packages[0].trackNumber : 'N/A'), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("table", _hoisted_32, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_33, [
                      _createElementVNode("img", {
                        src: _ctx.packages[0].qrCodeImage
                      }, null, 8, _hoisted_34)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packages[0].PackageTracking, (packageDetails) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "timeline-item",
                key: packageDetails.id
              }, [
                _createElementVNode("div", _hoisted_37, _toDisplayString(packageDetails.date), 1),
                _createElementVNode("div", _hoisted_38, [
                  (
                    packageDetails.status.internal ===
                      'Picked up from client store' ||
                    packageDetails.status.internal === 'Pickedup' ||
                    packageDetails.status.internal === 'Delivered' ||
                    packageDetails.status.internal === 'Ready to pickup' ||
                    packageDetails.status.internal === 'Ready to ship '
                  )
                    ? (_openBlock(), _createElementBlock("i", _hoisted_39))
                    : _createCommentVNode("", true),
                  (
                    packageDetails.status.internal === 'Under processing ' ||
                    packageDetails.status.internal === 'Reschedule'
                  )
                    ? (_openBlock(), _createElementBlock("i", _hoisted_40))
                    : _createCommentVNode("", true),
                  (
                    packageDetails.status.internal === 'Out for delivery ' ||
                    packageDetails.status.internal === 'Returned' ||
                    packageDetails.status.internal === 'Reject' ||
                    packageDetails.status.internal === 'In Store'
                  )
                    ? (_openBlock(), _createElementBlock("i", _hoisted_41))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("span", _hoisted_44, _toDisplayString(packageDetails.status.internal), 1),
                    (
                      packageDetails.status.internal === 'Returned' ||
                      packageDetails.status.internal === 'Reject'
                    )
                      ? (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString(packageDetails.rejectReason), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    (_ctx.packages[0].customerId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
          _createElementVNode("ul", _hoisted_47, [
            _createElementVNode("li", _hoisted_48, [
              _createElementVNode("a", _hoisted_49, _toDisplayString(_ctx.translate('customerId')), 1)
            ]),
            _createElementVNode("li", _hoisted_50, [
              _createElementVNode("a", _hoisted_51, _toDisplayString(_ctx.translate('customerSignature')), 1)
            ])
          ]),
          (_ctx.packages[0].customerId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("div", _hoisted_54, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1], (item, index) => {
                      return _createElementVNode("div", {
                        key: `propertyImage-${index}`,
                        class: _normalizeClass([{
                  'mb-7': [] - 1 !== index,
                }, "d-flex align-items-sm-center"])
                      }, [
                        _createElementVNode("div", _hoisted_55, [
                          _createElementVNode("div", {
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.downloadAttachment(_ctx.packages[0].customerId)), ["prevent"])),
                            class: "symbol-label contain-symbol",
                            style: _normalizeStyle(`background-image: url('${_ctx.packages[0].customerId}')`)
                          }, null, 4)
                        ]),
                        _createElementVNode("div", _hoisted_56, [
                          _createElementVNode("div", _hoisted_57, [
                            _createElementVNode("a", {
                              href: "#",
                              class: "text-gray-800 fw-bolder text-hover-primary fs-6 text-capitalize",
                              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
                        _ctx.downloadAttachment(_ctx.packages[0].customerId)
                      ), ["prevent"]))
                            }, _toDisplayString(_ctx.customerId), 1)
                          ]),
                          _createElementVNode("a", {
                            href: "#",
                            class: "btn btn-icon btn-light btn-sm border-0 me-3",
                            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.downloadAttachment(_ctx.packages[0].customerId)), ["prevent"]))
                          }, [
                            _createElementVNode("span", _hoisted_58, [
                              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr065.svg" })
                            ])
                          ])
                        ])
                      ], 2)
                    }), 64))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.packages[0].customerSignature)
            ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, [
                  _createElementVNode("div", _hoisted_61, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1], (item, index) => {
                      return _createElementVNode("div", {
                        key: `propertyImage-${index}`,
                        class: _normalizeClass([{
                  'mb-7': [] - 1 !== index,
                }, "d-flex align-items-sm-center"])
                      }, [
                        _createElementVNode("div", _hoisted_62, [
                          _createElementVNode("div", {
                            class: "symbol-label contain-symbol",
                            style: _normalizeStyle(`background-image: url('${_ctx.packages[0].customerSignature}')`)
                          }, null, 4)
                        ]),
                        _createElementVNode("div", _hoisted_63, [
                          _createElementVNode("div", _hoisted_64, [
                            _createElementVNode("a", {
                              href: "#",
                              class: "text-gray-800 fw-bolder text-hover-primary fs-6 text-capitalize",
                              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (
                        _ctx.downloadAttachment(_ctx.packages[0].customerSignature)
                      ), ["prevent"]))
                            }, _toDisplayString(_ctx.customerSignature), 1)
                          ]),
                          _createElementVNode("a", {
                            href: "#",
                            class: "btn btn-icon btn-light btn-sm border-0 me-3",
                            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (
                      _ctx.downloadAttachment(_ctx.packages[0].customerSignature)
                    ), ["prevent"]))
                          }, [
                            _createElementVNode("span", _hoisted_65, [
                              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr065.svg" })
                            ])
                          ])
                        ])
                      ], 2)
                    }), 64))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}