
  import { defineComponent, onMounted, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useRoute, useRouter } from 'vue-router';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'packages-overview',

    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const { can } = useAbility();
      const packages = ref();

      const translate = (text, options = {}) => {
        if (te(text)) {
          return t(text, options);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('packageOverview'), [
          translate('shipments'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_SHIPMENT_PACKAGE,
        route.params.id
      );

      const getBadgeClass = (val) => {
        let status = 'badge-light-success';
        if (!val) {
          status = 'badge-light-danger';
        }
        return status;
      };
      packages.value = data.data;

      const downloadAttachment = (item) => {
        window.open(item, '_blank');
      };

      return {
        downloadAttachment,
        goBack,
        packages,
        translate,
        can,
        getBadgeClass,
      };
    },
  });
